<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show || showWalkthroughModal"
      :loading="loading"
    >
      <template #modal>
        <modal-confirmation
          v-if="modal.show"
          title="Discard Data?"
          description="Are you sure want to go back? All your unsaved data will be lost"
          text-confirm="Discard"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
        <walkthrough-modal
          v-if="showWalkthroughModal"
          title="First Project!"
          @confirm="incrementWalkthrough"
          @cancel="cancelWalkthrough"
          description="
            Congratulations! You've earned yourself your first KIV Project!
            Fill in the project name and address and then click the Create button
            to create your project.
          "
          textCancel='Finish'
          textConfirm='Enter Details'
        />
      </template>

      <template #title>
        Create Project
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <!-- Image and name -->
          <div class="flex items-center flex-shrink-0 w-full mb-4">
            <circular-image-component
              :picture="picture"
              class="flex-shrink-0"
            />
            <div class="flex flex-col h-16 pl-3 text-left">
              <p class="text-2xl font-extrabold text-primary">
                New Project
              </p>
              <p class="text-sm text-content">
                *Landscape Pictures will display best
              </p>
            </div>
          </div>

          <!-- Project Info -->
          <form @submit.prevent="createProject" class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              :value.sync="project.name"
              :valid="validateInputs ? projectValidity.nameValid : true"
              label="Project Name"
              mode="form"
            />
            <text-input-component
              :value.sync="project.address"
              :valid="validateInputs ? projectValidity.addressValid : true"
              label="Postal / Zip Code"
              mode="form"
              type="postalCode"
            />
            <button class="hidden"/>
          </form>

          <!-- Actions -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <button-inline
              @click="takePicture"
              label="Update"
              value="Add Photo"
              mode="edit"
              :disabled="false"
            />
            <input
              @change="replacePicture" v-show='false'
              type="file" accept="image/*"
            />
          </div>

          <button-large
            @click="createProject()"
            color="green"
            class="self-center"
            :disabled="createProjectExecuted"
            :pulse="pulseCreate"
          >
            Create Project
          </button-large>
          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import {
  USER_WALKTHROUGH_DISPATCH,
  PROJECT_POST,
  USER_GET,
  PROJECTS_ALL,
} from '@/store/actions';
import {
  USER_WALKTHROUGH_STATE,
} from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import CircularImageComponent from '@/components/shared/general/CircularImageComponent.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';
import { postalCodeValidation } from '@/utils/validators';
import WalkthroughModal from '../../components/walkthrough/WalkthroughModal.vue';

export default {
  name: 'ProjectCreate',
  components: {
    InteriorPageContentContainer,
    ModalConfirmation,
    WalkthroughModal,
    CircularImageComponent,
    TextInputComponent,
    ButtonInline,
    ButtonLarge,
  },
  data() {
    return {
      project: {
        name: '',
        address: '',
        picture: null,
      },
      projectValidity: {
        nameValid: false,
        addressValid: false,
      },
      modal: {
        show: false,
      },
      validateInputs: false,
      edited: false,
      actionComplete: false,
      isNewProject: !this.$route.params.project_id,
      to: { name: 'Projects' },
      defaultBackRoute: { name: 'Projects' },
      createProjectExecuted: false,
      loading: true,
    };
  },
  computed: {
    picture() {
      return this.project.picture
        ? this.pictureUrl(this.project.picture)
        : '/img/default-project-images/house.jpg';
    },
    valid() {
      return this.projectValidity.nameValid && this.projectValidity.addressValid;
    },
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 6;
    },
    pulseCreate() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 7;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.edited && !this.actionComplete) {
      this.to = to;
      this.modal.show = true;
    } else {
      const stage = this.$store.getters[USER_WALKTHROUGH_STATE];
      if (stage < 100) {
        if (to.name === 'Project') {
          this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
        } else if (to.name !== 'PaymentPlan' && this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
          this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
        }
      }
      next();
    }
  },
  async created() {
    const isNavigating = false;

    // This code has been commented out so as to deactivate the payment portion
    /// ////////////////////////////////////////////////////////////////////////
    const plan = await this.$store.dispatch(USER_GET)
      .then((user) => {
        console.log(user);
        if (!user.plan) {
        //   isNavigating = true;
        //   this.$router.replace({ name: 'PaymentPlan' });
        }
        return user.plan;
      });
    await this.$store.dispatch(PROJECTS_ALL)
      .then((projectList) => {
        if (plan === 'Standard' && projectList.length === 1) {
        //   isNavigating = true;
        //   this.$router.replace({ name: 'PaymentPlan' });
        }
      });

    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 6) {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 6);
    }
    this.loading = isNavigating;
  },
  watch: {
    project: {
      handler(newVal) {
        this.projectValidity.nameValid = newVal.name.length > 0;
        this.projectValidity.addressValid = postalCodeValidation(newVal.address);

        if (this.project.name !== '') this.edited = true;
        else if (this.project.address !== '') this.edited = true;
        else this.edited = false;
      },
      deep: true,
    },
  },
  methods: {
    incrementWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
    async createProject() {
      this.validateInputs = true;
      if (!this.valid) return;
      this.createProjectExecuted = true;
      this.edited = false;
      this.$store.dispatch(PROJECT_POST, {
        name: this.project.name,
        address: this.project.address,
        picture: await this.getPictureFile(),
      })
        .then((newProject) => {
          this.actionComplete = true;
          this.$router.push({
            name: 'Project',
            params: {
              project_id: newProject.id,
              tab: 'Declutter',
            },
          });
        })
        .catch(() => {
          console.error('The Project was not created!!');
        });
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$router.push(this.to);
    },
    modalCancel() {
      this.modal.show = false;
      this.to = this.defaultBackRoute;
    },
    backNav() {
      this.$router.push(this.defaultBackRoute);
    },

    async getPictureFile() {
      if (this.project.picture) return this.project.picture;
      return fetch(this.picture)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => new File([blob], 'default-project-image.jpeg', { type: blob.type }));
    },
    pictureUrl(obj) {
      return typeof obj === typeof '' ? obj : URL.createObjectURL(obj);
    },
    replacePicture(event) {
      this.project = {
        ...this.project,
        picture: event.target.files[0],
      };
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },

  },

};
</script>
